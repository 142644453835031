import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/second-chance.scss"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SecondChanceProgram = () => {
  return (
    <Layout>
      <SEO
        title="Second Chance Program"
        description="It is time for a brand new, healthy, beautiful smile at Innovative Oral Surgery & Dental Implant Studio."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/second-chance-desktop-hero-image"
            alt="second chance banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/second-chance-mobile-hero-image"
            alt="second chance banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity for a Second Chance to Smile</h1>

            <p>
              Innovative Oral Surgery & Dental Implant Studio is proud to
              announce its Second Chance program. This year, this life-changing
              program will provide one deserving recipient from the Louisville
              metro area and one from the Bardstown area with a complete smile
              makeover free of charge.
            </p>
            <p>
              The ideal candidate has multiple missing or failing teeth and
              cannot afford restorative treatment. After the submission period,
              we will select one recipient to receive a free{" "}
              <Link
                to="/full-arch-restoration/"
                title="Learn more about full-arch-restoration">
                full-arch restoration
              </Link>{" "}
              treatment concept procedure. We encourage anyone in our community
              who is dreaming of a healthy, beautiful smile to apply!
            </p>
            <h2 style={{ fontSize: "24px" }}>Full-Arch Restoration</h2>
            <p>
              An entire smile can be restored in a single procedure. With a
              full-arch restoration, a customized prosthesis is secured onto the
              dental implant posts on the upper or lower arch. After treatment,
              patients are able to eat, speak, and smile with confidence again!
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container">
            <p>
              <b>
                Thank you for your interest in the Second Chance program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </b>
            </p>
          </div>
        </div>
        <div className="sas__section">
          <div className="sas__container">
            <div className="sas__brackets">
              <div className="sas__about-grid">
                <div>
                  <p className="sas__subheading">How To Apply</p>
                  <ul>
                    <li>Complete the short application.</li>
                    <li>Upload photos of your smile and teeth.</li>
                    <li>Submit your application by November 6th, 2024.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="sas__section">
          <div className="sas__container">
            <ImageSlider
              useDots
              adaptiveHeight
              images={[
                "ELOS/DEV/second-chance-recipient-2023-1",
                "ELOS/DEV/second-chance-recipient-2023-2",
                "ELOS/DEV/second-chance-recipient-2023-3",
                "ELOS/DEV/second-chance-recipient-2023-4"
              ]}
            />
          </div>
        </div> */}

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              Stay tuned to this page and{" "}
              <a
                href="https://www.facebook.com/eastlouisvilleoralsurgery"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              for program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-louisville-ky/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondChanceProgram
